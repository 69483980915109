<template>
    <div class="page">

         <div class="" style="text-align:left;padding-left:20px;padding-top:20px">
            <a-button  class="addBtn fixedBtn" size="large" type="primary" @click="showAdd">添加工装</a-button>
        </div>
        <div class="lists" v-for="item in data" :key="item.id">
         <div class="list_row">
              <div class="flex">
                    <div><label for="">工服名称：</label>{{item.name}}</div>
                </div>
                <div class="flex">
                    <!-- <div><label for="">编号：</label>{{item.id}}</div> -->
                    <div><label for="">工服价格：</label>{{item.salary}}</div>
                </div>
            </div>
            <div class="btn_con">
                <!-- <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button> -->
                <a-button type="primary" size="small" ghost @click="editType(item)">编辑</a-button>
                <!-- <a-popconfirm title="确定要关闭吗?"  @confirm="() => off(item.id)">
                    <a-button type="danger" size="small" ghost>关闭</a-button>
                </a-popconfirm> -->
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>
            </div>
        </div>
        <a-modal v-model="visible" title="添加"  @ok="addType">
            <div class="addBox">
                <div class="add_title">请输入工服名称</div>
                <a-input class="add_inp" v-model="addText" />
                 <div class="add_title">请输入工服价格</div>
                <a-input class="add_inp" type="number" v-model="addPrice" />
            </div>
        </a-modal>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,ajaxUrl} from '../../../assets/js/request';
export default {
    data() {
        return {
            // 搜索的内容
            key: "",
            data: '',
            ajaxUrl:ajaxUrl,
            visible:false,
            onID:'', //编辑状态id
            addText:'', //添加分类名称
            addPrice:'',

        };
    },
    mounted: function(){
        this.getData();
    },
    methods:{
        // 搜索
        onSearch(){
            this.getData();
        },
       getData() {
             requestXml("/jieb/Worker/clotypeshow","POST",(res) => {
                // console.log(res);
                this.data = res.list;
            },{"page":{"curpage":1,"pagesize":9999}})
        },
      //添加分类
        showAdd(){
            this.visible = true;
        },
        addType(){
            // console.log(this.addText);
            if(this.addText!=""&&this.addPrice!=''){
            let url="/jieb/Worker/clotypeadd"
            let data={'name':this.addText,'salary':this.addPrice}
            if(this.onID){
                url="/jieb/Worker/clotypedit"
                data.id=this.onID;
            }
            
            requestXml(url,"POST",(res) => {
                console.log(res)
                this.getData();      
                this.visible=false;
                this.onID='';
                this.addText='';
                this.addPrice='';
                // location.reload();
            },data)
            }else{
                this.$message.info('请输入工服名称或工服价格');

            }
         
        },
        // 编辑
        editType(res){
            this.onID=res.id;
            this.addText=res.name;
            this.addPrice=res.salary;
            this.visible=true;
        },
        // 删除
        onDelete(id){
            // console.log(id);
            requestXml("/jieb/Worker/coltypedel","POST",(res) => {
                console.log(res)
                this.getData();
            },{"id": id})
        }
        
    }
};
</script>
<style scoped>
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }
    .fixedBtn{
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 999;
        height: 43px;
        border-radius: 50px;
    }
    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;

    }
    .lists .item{
        display: flex;
    }    
    .lists .right{
        text-align: left;
        padding-left: 20px;
        width: calc(100% - 100px );
    }
     .lists .right .flex{
         display: flex;
         justify-content: space-between;
     }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
    .add_title{
        padding-top: 10px;padding-bottom: 5px;
    }
    
</style>
